export const sidebarE = [
    {
        label: "Inicio",
        icon: <svg width="23" height="24" viewBox="0 0 23 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.75 20.1914V13.6295C7.75 13.0255 8.26487 12.5359 8.9 12.5359H12.35C12.9851 12.5359 13.5 13.0255 13.5 13.6295V20.1914M9.95855 3.14378L2.48355 8.19885C2.18018 8.40401 2 8.73612 2 9.09012V18.5509C2 19.4569 2.77231 20.1914 3.725 20.1914H17.525C18.4777 20.1914 19.25 19.4569 19.25 18.5509V9.09012C19.25 8.73612 19.0698 8.40401 18.7665 8.19885L11.2915 3.14378C10.8924 2.87395 10.3576 2.87395 9.95855 3.14378Z" stroke="#FBFBFB" stroke-width="1.8" stroke-linecap="round" />
        </svg>,
        link: "inicio"
    },
    {
        label: "Directorio",
        icon: <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.17143 1.13379V20.3338M16.0857 9.36236H11.2857M16.0857 5.24807H11.2857M3.74286 5.24807H1M3.74286 9.36236H1M3.74286 13.4766H1M5.11429 20.3338H17.4571C18.972 20.3338 20.2 19.1058 20.2 17.5909V3.87665C20.2 2.36181 18.972 1.13379 17.4571 1.13379H5.11429C3.59945 1.13379 2.37143 2.36181 2.37143 3.87665V17.5909C2.37143 19.1058 3.59945 20.3338 5.11429 20.3338Z" stroke="white" stroke-width="2" stroke-linecap="round" />
        </svg>,
        link: "directorio"
    },
    {
        label: "Catálogo Integral de Ofertas",
        icon: <svg width="21" height="22" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.65 7.24883V4.37383C13.65 2.46845 12.1054 0.923828 10.2 0.923828C8.29462 0.923828 6.75 2.46845 6.75 4.37383V7.24883M3.2303 20.4738H17.1697C18.4015 20.4738 19.4 19.4938 19.4 18.285L17.9712 6.6738C17.9712 5.46493 16.9727 4.48495 15.7409 4.48495H4.3803C3.14854 4.48495 2.15 5.46493 2.15 6.6738L1 18.285C1 19.4938 1.99854 20.4738 3.2303 20.4738Z" stroke="white" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round" />
        </svg>,
        link: "catalogo"
    },
    {
        label: "Ofertas Laborales",
        icon: <svg width="21" height="17" viewBox="0 0 21 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.6901 4.38144V2.20267C6.6901 1.60102 7.19961 1.11328 7.82812 1.11328H11.8112C12.4397 1.11328 12.9492 1.60102 12.9492 2.20267V4.38144M3.27604 15.2753H16.9323C18.1893 15.2753 19.2083 14.2998 19.2083 13.0965V6.56022C19.2083 5.35691 18.1893 4.38144 16.9323 4.38144H3.27604C2.01902 4.38144 1 5.35691 1 6.56022V13.0965C1 14.2998 2.01902 15.2753 3.27604 15.2753Z" stroke="white" stroke-width="2" stroke-linecap="round" />
        </svg>,
        link: "/ofertas"
    },
    {
        label: "Perfil",
        icon: <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4 17.8C4.46117 17.2835 6.62105 14.9067 7.25401 14.9067H13.9464C14.8636 14.9067 16.736 16.8769 17.2 17.5714M20.2 10.6C20.2 15.9019 15.9019 20.2 10.6 20.2C5.29807 20.2 1 15.9019 1 10.6C1 5.29807 5.29807 1 10.6 1C15.9019 1 20.2 5.29807 20.2 10.6ZM14.0388 7.32796C14.0388 5.49645 12.4927 4 10.6003 4C8.70791 4 7.16174 5.49645 7.16174 7.32796C7.16174 9.15947 8.70791 10.6559 10.6003 10.6559C12.4927 10.6559 14.0388 9.15947 14.0388 7.32796Z" stroke="white" stroke-width="2" />
        </svg>
        ,
        link: "perfil"
    },

    {
        label: "Mis Ventas",
        icon: <svg width="25" height="25" viewBox="0 0 61 61" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20 38C22.7657 36.1726 25.335 33.7886 35.7456 37.1809C36.215 37.3338 36.7218 37.5933 37.0506 37.9615V37.9615C38.3704 39.4393 37.5573 41.784 36.1276 43.1558V43.1558C35.2722 43.9765 34.1302 44.4371 32.9447 44.4371H29.7929H37.2811C38.1004 44.4371 38.9136 44.2461 39.5918 43.7866C41.3374 42.6038 43.4744 40.6878 45.1044 39.1185C46.2011 38.0625 47.7824 37.3385 49.1065 38.0901C50.3467 38.794 50.9166 39.9082 51.1245 40.897C51.3031 41.7473 50.943 42.5919 50.4089 43.2773C46.5476 48.2316 41.9909 51.2455 39.45 52.5032C38.8502 52.8001 38.1841 52.9147 37.5149 52.9031L18 52.5671M16.9668 52.5671V52.5671C16.9668 54.4356 15.4517 55.9504 13.5831 55.9504C12.4619 55.9504 11.4209 55.9504 10.3528 55.9504C7.59134 55.9504 5.35254 53.7115 5.35254 50.9501C5.35254 47.0367 5.35254 43.7565 5.35254 39.8431C5.35254 37.0816 7.59134 34.8428 10.3528 34.8428C10.893 34.8428 11.4263 34.8428 11.9666 34.8428C14.728 34.8428 16.9668 37.0824 16.9668 39.8438C16.9668 44.0489 16.9668 47.4899 16.9668 52.5671Z" stroke="white" stroke-width="4" />
            <path d="M27 22C27 17.0294 31.0294 13 36 13C40.9706 13 45 17.0294 45 22C45 26.9706 40.9706 31 36 31C31.0294 31 27 26.9706 27 22Z" stroke="white" stroke-width="4" />
            <path d="M34.7416 12.8768C34.7416 8.55407 31.2374 5.0498 26.9146 5.0498C22.5919 5.0498 19.0876 8.55407 19.0876 12.8768C19.0876 17.1995 22.5919 20.7038 26.9146 20.7038" stroke="white" stroke-width="4" />
        </svg>,
        link: "/ventas"
    },
    {
        label: "Mis Compras",
        icon: <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 0.633203C0.502944 0.633203 0.1 1.03615 0.1 1.5332C0.1 2.03026 0.502944 2.4332 1 2.4332V0.633203ZM3.02985 1.5332L3.89915 1.30013C3.79367 0.906733 3.43714 0.633203 3.02985 0.633203V1.5332ZM6.32836 13.8358L5.45906 14.0689C5.57554 14.5033 5.99486 14.7851 6.4411 14.7287L6.32836 13.8358ZM16.4776 12.5543L16.5904 13.4472C16.9728 13.3989 17.282 13.1125 17.3593 12.7349L16.4776 12.5543ZM18 5.12146L18.8817 5.30205C18.9359 5.03719 18.8682 4.76196 18.6973 4.55248C18.5264 4.343 18.2704 4.22146 18 4.22146V5.12146ZM3.99192 5.12146L3.12262 5.35453L3.99192 5.12146ZM1 2.4332H3.02985V0.633203H1V2.4332ZM6.4411 14.7287L16.5904 13.4472L16.3649 11.6614L6.21561 12.9429L6.4411 14.7287ZM17.3593 12.7349L18.8817 5.30205L17.1183 4.94087L15.5959 12.3737L17.3593 12.7349ZM2.16055 1.76627L3.12262 5.35453L4.86121 4.88839L3.89915 1.30013L2.16055 1.76627ZM3.12262 5.35453L5.45906 14.0689L7.19766 13.6027L4.86121 4.88839L3.12262 5.35453ZM18 4.22146H3.99192V6.02146H18V4.22146ZM8.6 17.1916C8.6 17.4441 8.39979 17.6332 8.17188 17.6332V19.4332C9.41097 19.4332 10.4 18.421 10.4 17.1916H8.6ZM8.17188 17.6332C7.94397 17.6332 7.74375 17.4441 7.74375 17.1916H5.94375C5.94375 18.421 6.93278 19.4332 8.17188 19.4332V17.6332ZM7.74375 17.1916C7.74375 16.9391 7.94397 16.75 8.17188 16.75V14.95C6.93278 14.95 5.94375 15.9622 5.94375 17.1916H7.74375ZM8.17188 16.75C8.39979 16.75 8.6 16.9391 8.6 17.1916H10.4C10.4 15.9622 9.41097 14.95 8.17188 14.95V16.75ZM15.6833 17.1916C15.6833 17.4441 15.4831 17.6332 15.2552 17.6332V19.4332C16.4943 19.4332 17.4833 18.421 17.4833 17.1916H15.6833ZM15.2552 17.6332C15.0273 17.6332 14.8271 17.4441 14.8271 17.1916H13.0271C13.0271 18.421 14.0161 19.4332 15.2552 19.4332V17.6332ZM14.8271 17.1916C14.8271 16.9391 15.0273 16.75 15.2552 16.75V14.95C14.0161 14.95 13.0271 15.9622 13.0271 17.1916H14.8271ZM15.2552 16.75C15.4831 16.75 15.6833 16.9391 15.6833 17.1916H17.4833C17.4833 15.9622 16.4943 14.95 15.2552 14.95V16.75Z" fill="white" />
        </svg>,
        link: "/compras"
    },
    {
        label: "Estadísticas",
        icon: <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.2001 1.29688C15.281 1.2969 19.4 5.23682 19.4 10.0969C19.4 14.957 15.281 18.8969 10.2 18.8969C5.11898 18.8969 1 14.957 1 10.0969C1 5.23678 5.11901 1.29685 10.2001 1.29688ZM10.2001 1.29688L10.2 10.0969M10.2 10.0969L3.875 16.1469M10.2 10.0969L3.875 4.04693" stroke="white" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round" />
        </svg>,
        link: "/estadisticas"
    },
    {
        label: "Planes y Facturación",
        icon: <svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.30156 20.6003H3.50155C2.17607 20.6003 1.10155 19.5258 1.10156 18.2003L1.10166 3.80038C1.10166 2.4749 2.17618 1.40039 3.50166 1.40039H14.3019C15.6274 1.40039 16.7019 2.47491 16.7019 3.80039V10.4004M11.9019 17.2004L14.1019 19.4004L18.9019 14.6002M5.30194 6.20039H12.5019M5.30194 9.80039H12.5019M5.30194 13.4004H8.90194" stroke="white" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round" />
        </svg>,
        link: "/planes"
    },
    {
        label: "Soporte Técnico",
        icon: <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M22.312 6.56608C22.2956 6.37617 21.8718 6.01147 21.6924 5.94642C21.5126 5.88097 21.3113 5.92553 21.176 6.06007L18.4624 8.77367L14.9377 5.24899L17.7056 2.49152C17.8411 2.35671 17.8853 2.15613 17.82 1.9773C17.7541 1.79811 17.3195 1.41849 17.1285 1.40204C15.4475 1.25775 13.8004 1.85566 12.6096 3.04218C10.9787 4.66711 10.5468 7.03998 11.3116 9.06255C11.228 9.13271 11.1455 9.20706 11.0642 9.28723L1.82492 17.9726C1.82166 17.9758 1.81845 17.9793 1.81492 17.9824C0.728358 19.0649 0.728358 20.8262 1.81492 21.9089C2.90167 22.9914 4.65673 22.9782 5.74312 21.8957C5.74781 21.8913 5.75209 21.887 5.75638 21.8821L14.3986 12.606C14.4773 12.5272 14.5505 12.4447 14.6194 12.36C16.6502 13.1234 19.0336 12.6941 20.6659 11.0681C21.8565 9.88149 22.457 8.2405 22.312 6.56608Z" stroke="white" stroke-width="1.8" stroke-linejoin="round" />
        </svg>,
        link: "/soporte"
    },
];