export const sidebarP = [
    {
        label: "Inicio",
        icon: <svg width="23" height="24" viewBox="0 0 23 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.75 20.1914V13.6295C7.75 13.0255 8.26487 12.5359 8.9 12.5359H12.35C12.9851 12.5359 13.5 13.0255 13.5 13.6295V20.1914M9.95855 3.14378L2.48355 8.19885C2.18018 8.40401 2 8.73612 2 9.09012V18.5509C2 19.4569 2.77231 20.1914 3.725 20.1914H17.525C18.4777 20.1914 19.25 19.4569 19.25 18.5509V9.09012C19.25 8.73612 19.0698 8.40401 18.7665 8.19885L11.2915 3.14378C10.8924 2.87395 10.3576 2.87395 9.95855 3.14378Z" stroke="#FBFBFB" stroke-width="1.8" stroke-linecap="round" />
        </svg>,
        link: "/inicio"
    },
    {
        label: "Directorio",
        icon: <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.17143 1V20.2M16.0857 9.22857H11.2857M16.0857 5.11429H11.2857M3.74286 5.11429H1M3.74286 9.22857H1M3.74286 13.3429H1M5.11429 20.2H17.4571C18.972 20.2 20.2 18.972 20.2 17.4571V3.74286C20.2 2.22802 18.972 1 17.4571 1H5.11429C3.59945 1 2.37143 2.22802 2.37143 3.74286V17.4571C2.37143 18.972 3.59945 20.2 5.11429 20.2Z" stroke="white" stroke-width="2" stroke-linecap="round" />
        </svg>
        ,
        link: "DirectorioPro"
    },
    {
        label: "Bolsa de trabajo",
        icon: <svg width="21" height="18" viewBox="0 0 21 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.9375 5.02158V2.74808C6.9375 2.12027 7.46916 1.61133 8.125 1.61133H12.2812C12.9371 1.61133 13.4688 2.12027 13.4688 2.74808V5.02158M3.375 16.3891H17.625C18.9367 16.3891 20 15.3712 20 14.1156V7.29509C20 6.03947 18.9367 5.02158 17.625 5.02158H3.375C2.06332 5.02158 1 6.03947 1 7.29509V14.1156C1 15.3712 2.06332 16.3891 3.375 16.3891Z" stroke="white" stroke-width="2" stroke-linecap="round" />
        </svg>
        ,
        link: "bolsap"
    },
    {
        label: "Perfil",
        icon: <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4 17.8C4.46117 17.2835 6.62105 14.9067 7.25401 14.9067H13.9464C14.8636 14.9067 16.736 16.8769 17.2 17.5714M20.2 10.6C20.2 15.9019 15.9019 20.2 10.6 20.2C5.29807 20.2 1 15.9019 1 10.6C1 5.29807 5.29807 1 10.6 1C15.9019 1 20.2 5.29807 20.2 10.6ZM14.0388 7.32796C14.0388 5.49645 12.4927 4 10.6003 4C8.70791 4 7.16174 5.49645 7.16174 7.32796C7.16174 9.15947 8.70791 10.6559 10.6003 10.6559C12.4927 10.6559 14.0388 9.15947 14.0388 7.32796Z" stroke="white" stroke-width="2" />
        </svg>
        ,
        link: "perfil"
    },
    {
        label: "Mis compras",
        icon: <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 0.400391C0.447715 0.400391 0 0.848106 0 1.40039C0 1.95268 0.447715 2.40039 1 2.40039V0.400391ZM3.29254 1.40039L4.25776 1.13898C4.13971 0.703073 3.74414 0.400391 3.29254 0.400391V1.40039ZM7.01791 15.1556L6.05268 15.417C6.18295 15.898 6.64747 16.2097 7.14194 16.1479L7.01791 15.1556ZM18.4806 13.7228L18.6046 14.7151C19.0293 14.662 19.3731 14.3445 19.4599 13.9254L18.4806 13.7228ZM20.2 5.41233L21.1793 5.61494C21.2402 5.32042 21.1653 5.01412 20.9754 4.78091C20.7855 4.54769 20.5007 4.41233 20.2 4.41233V5.41233ZM4.3791 5.41233L3.41388 5.67375L4.3791 5.41233ZM1 2.40039H3.29254V0.400391H1V2.40039ZM7.14194 16.1479L18.6046 14.7151L18.3566 12.7305L6.89387 14.1633L7.14194 16.1479ZM19.4599 13.9254L21.1793 5.61494L19.2207 5.20973L17.5013 13.5202L19.4599 13.9254ZM2.32731 1.66181L3.41388 5.67375L5.34433 5.15092L4.25776 1.13898L2.32731 1.66181ZM3.41388 5.67375L6.05268 15.417L7.98314 14.8942L5.34433 5.15092L3.41388 5.67375ZM20.2 4.41233H4.3791V6.41233H20.2V4.41233ZM9.6 18.9077C9.6 19.1838 9.37614 19.4077 9.1 19.4077V21.4077C10.4807 21.4077 11.6 20.2884 11.6 18.9077H9.6ZM9.1 19.4077C8.82386 19.4077 8.6 19.1838 8.6 18.9077H6.6C6.6 20.2884 7.71929 21.4077 9.1 21.4077V19.4077ZM8.6 18.9077C8.6 18.6315 8.82386 18.4077 9.1 18.4077V16.4077C7.71929 16.4077 6.6 17.527 6.6 18.9077H8.6ZM9.1 18.4077C9.37614 18.4077 9.6 18.6315 9.6 18.9077H11.6C11.6 17.527 10.4807 16.4077 9.1 16.4077V18.4077ZM17.6 18.9077C17.6 19.1838 17.3761 19.4077 17.1 19.4077V21.4077C18.4807 21.4077 19.6 20.2884 19.6 18.9077H17.6ZM17.1 19.4077C16.8239 19.4077 16.6 19.1838 16.6 18.9077H14.6C14.6 20.2884 15.7193 21.4077 17.1 21.4077V19.4077ZM16.6 18.9077C16.6 18.6315 16.8239 18.4077 17.1 18.4077V16.4077C15.7193 16.4077 14.6 17.527 14.6 18.9077H16.6ZM17.1 18.4077C17.3761 18.4077 17.6 18.6315 17.6 18.9077H19.6C19.6 17.527 18.4807 16.4077 17.1 16.4077V18.4077Z" fill="white" />
        </svg>
        ,
        link: "/compras"
    },
    {
        label: "Soporte Técnico",
        icon: <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M12.8626 3.36877C12.3875 1.41874 9.61246 1.41874 9.13744 3.36877C9.06652 3.6618 8.92742 3.93394 8.73146 4.16305C8.53549 4.39216 8.2882 4.57177 8.00971 4.68726C7.73122 4.80274 7.4294 4.85085 7.1288 4.82766C6.8282 4.80447 6.53733 4.71063 6.27985 4.55379C4.5648 3.50877 2.60224 5.47131 3.64727 7.18633C4.32229 8.29385 3.72352 9.73887 2.46348 10.0451C0.512172 10.5189 0.512172 13.2952 2.46348 13.7677C2.75659 13.8387 3.02878 13.9779 3.2579 14.174C3.48701 14.3701 3.66657 14.6176 3.78195 14.8962C3.89733 15.1749 3.94527 15.4768 3.92187 15.7775C3.89846 16.0782 3.80438 16.369 3.64727 16.6265C2.60224 18.3415 4.5648 20.304 6.27985 19.259C6.53728 19.1019 6.82818 19.0078 7.12886 18.9844C7.42954 18.961 7.73149 19.009 8.01013 19.1243C8.28877 19.2397 8.53623 19.4193 8.73234 19.6484C8.92845 19.8775 9.06768 20.1497 9.13869 20.4428C9.61246 22.3941 12.3888 22.3941 12.8613 20.4428C12.9326 20.1498 13.0719 19.8778 13.2681 19.6489C13.4642 19.4199 13.7116 19.2405 13.9902 19.1251C14.2688 19.0098 14.5706 18.9618 14.8712 18.9851C15.1718 19.0083 15.4627 19.1022 15.7201 19.259C17.4352 20.304 19.3978 18.3415 18.3527 16.6265C18.1959 16.369 18.102 16.0781 18.0788 15.7775C18.0555 15.4769 18.1035 15.1751 18.2189 14.8966C18.3342 14.618 18.5136 14.3706 18.7426 14.1744C18.9716 13.9783 19.2436 13.8389 19.5365 13.7677C21.4878 13.2939 21.4878 10.5176 19.5365 10.0451C19.2434 9.97412 18.9712 9.83489 18.7421 9.63878C18.513 9.44267 18.3334 9.19522 18.218 8.91658C18.1027 8.63795 18.0547 8.336 18.0781 8.03533C18.1015 7.73465 18.1956 7.44376 18.3527 7.18633C19.3978 5.47131 17.4352 3.50877 15.7201 4.55379C15.4627 4.71089 15.1718 4.80498 14.8711 4.82838C14.5705 4.85179 14.2685 4.80385 13.9899 4.68847C13.7112 4.57309 13.4638 4.39353 13.2677 4.16442C13.0715 3.93531 12.9323 3.66312 12.8613 3.37002L12.8626 3.36877Z" stroke="white" stroke-width="2" />
            <path d="M13.2222 11.9062C13.2222 13.1335 12.2273 14.1285 11 14.1285C9.7727 14.1285 8.77778 13.1335 8.77778 11.9062C8.77778 10.6789 9.7727 9.68403 11 9.68403C12.2273 9.68403 13.2222 10.6789 13.2222 11.9062Z" stroke="white" stroke-width="2" />
        </svg>
        ,
        link: "soporte"
    },
    {
        label: "Acerca de la App",
        icon: <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.99902 14.9062V14.9458M7.75 7.63624C7.75 6.37013 8.75736 5.34375 10 5.34375C11.2426 5.34375 12.25 6.37013 12.25 7.63624C12.25 8.90235 11.2426 9.92874 10 9.92874C10 9.92874 9.99902 10.613 9.99902 11.4571M19 10.4062C19 15.3768 14.9706 19.4062 10 19.4062C5.02944 19.4062 1 15.3768 1 10.4062C1 5.43569 5.02944 1.40625 10 1.40625C14.9706 1.40625 19 5.43569 19 10.4062Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
        ,
        link: "/acerca"
    },
    {
        label: "Mis empresas",
        icon: <svg width="24" height="18" viewBox="0 0 24 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.2002 7.88078C3.2002 6.73203 3.69268 5.80078 4.3002 5.80078H10.9002C11.5077 5.80078 12.0002 6.73203 12.0002 7.88078V14.1208C12.0002 15.2695 11.5077 16.2008 10.9002 16.2008H4.3002C3.69268 16.2008 3.2002 15.2695 3.2002 14.1208V7.88078Z" stroke="white" stroke-width="2" />
            <path d="M12 4.04C12 2.36105 12.4925 1 13.1 1H19.7C20.3075 1 20.8 2.36105 20.8 4.04V13.16C20.8 14.8389 20.3075 16.2 19.7 16.2H13.1C12.4925 16.2 12 14.8389 12 13.16V4.04Z" stroke="white" stroke-width="2" />
            <rect x="0.4" y="15.8004" width="23.2" height="0.8" rx="0.4" stroke="white" stroke-width="0.8" />
        </svg>
        ,
        link: "/empresas"
    }
];