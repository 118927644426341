import React, { useEffect, useState } from 'react'
import { Button } from 'primereact/button'
import { useDispatch } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { ScrollPanel } from 'primereact/scrollpanel'

import { logout } from '../../Redux/authSlice'

import LogoLargoB from "../../Images/Sidebar/LogoLargoB.png"
import CerrarSesion from "../../Images/Sidebar/CerrarSesion.svg"
import In from '../../Images/Sidebar/in-recortado.png'
import "../../Styles/Sidebar.css"

const Sidebar = ({ SidebarLista }) => {
  const [OpenSidebar, setOpenSidebar] = useState(true);
  const dispatch = useDispatch();
  const handleLogout = () => {
    dispatch(logout());
  }

  //! RESPONSIVE
  useEffect(() => {
    const handleResize = () => {
      /* MUESTRA EL BANNER PEQUEÑO */
      if (window.innerWidth <= 1025) setOpenSidebar(false)
      /* MUESTRA EL BANNER GRANDE */
      else setOpenSidebar(true);
    };
    handleResize();

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className={`${OpenSidebar ? 'contenedorGrande contenedor ' : 'contenedor-minimizado'} bg-blue-dark justify-content-between flex flex-column`}>
      <div className='pl-3 flex flex-column'>
        <div className={OpenSidebar ? 'pr-3' : 'p-2'}>
          <img src={OpenSidebar ? LogoLargoB : In} alt="" className={`${OpenSidebar ? 'w-full' : 'h-2rem'} mt-6`} />
          <button className={`${OpenSidebar ? 'botonNoMinimizado' : 'botonMinimizado'} botonMinimizar bg-transparent border-none cursor-pointer`} onClick={() => { setOpenSidebar(!OpenSidebar) }}>
            <i className={`${OpenSidebar ? 'pi pi-arrow-left' : 'pi pi-arrow-right text-xs'} p-3 text-green01 bg-blue-dark border-circle`} style={{ border: "5px solid white" }}></i>
          </button>
          <hr className={OpenSidebar ? 'mt-7' : 'ml-0 mr-3 mt-7'} />
        </div>
        <ScrollPanel className="responsive-scroll" >
          <div className={`${OpenSidebar ? 'w-full' : ''}`}>
            {SidebarLista?.map((elemento) => (
              <NavLink to={elemento.link} className={`px-3 flex flex-row seleccionarOpcionP ${OpenSidebar ? 'w-full' : ''}`} style={{ textDecoration: "none", padding: "12px 12px" }}>
                <label className='flex align-items-center'>{elemento.icon}</label>
                {OpenSidebar ? <label className='pl-3 text-white truncate-text'>{elemento.label}</label> : ''}
              </NavLink>
            ))}
          </div>
        </ScrollPanel>
      </div>
      <div className='px-3 gap-3 justify-content-center flex flex-column align-items-center mb-6 bg-blue-dark z-1' style={{ position: "sticky", bottom: 0 }}>
        <hr className='w-full' />
        <Button className='flex flex-row align-content-center bg-transparent border-none gap-3' onClick={() => handleLogout()}>
          <img src={CerrarSesion} alt="" className='logo' />
          {OpenSidebar ? <span className='pr-3 text-white truncate-text'>Cerrar Sesión</span> : ''}
        </Button>
        {OpenSidebar ? <span className="text-center text-white truncate-text">© 2024 InduMatch</span> : ''}
      </div>
    </div>
  )
}

export default Sidebar;
