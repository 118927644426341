import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { history } from "../history";
import axios from "../http-common";
// const { http } = AuthUser();

const initialState = () => {
  return {
    id_user: JSON.parse(localStorage.getItem("id_user")),
    tipo_user: JSON.parse(localStorage.getItem("tipo_user")),
    token: JSON.parse(localStorage.getItem("token")),
    finishWithErrors: false,
    errorMessage: "Fallas al solicitar",
    loading: false, // Añadí loading al estado inicial
  };
};

// let Ruta = null;
export const signInUser = createAsyncThunk("signInUser", async (body) => {
  return axios.http.post("/login", body);
});

// maneja la autenticación de usuarios.
const authSlice = createSlice({
  name: "login", // El nombre del slice
  initialState: initialState(), // El estado inicial del slice
  //TODO: Los reducers son funciones que modifican el estado del slice de manera síncrona
  reducers: {
    // Un reducer para agregar un token
    addToken: (state, action) => {
      console.log("agregando token");
    },
    // Un reducer para realizar la acción de logout (CERRAR SESIÓN)
    logout: (state, action) => {
      state.id_user = null;
      state.data = null;
      state.tipo_user = null;
      localStorage.removeItem("token");
      localStorage.removeItem("tipo_user");
      localStorage.removeItem("id_user");
      // Redirecciona al usuario a la página de login
      history.navigate("login");
    },
  },

  //TODO: Los extraReducers son reducers que responden a acciones asincrónicas
  extraReducers: (builder) => {
    //! Reducer para la acción pendiente de signInUser
    builder.addCase(signInUser.pending, (state, action) => {
      // Establece el estado de carga en true para indicar que se está realizando una acción de autenticación
      state.loading = true;
    });
    //! Reducer para la acción exitosa de signInUser
    //? data -> Es la parte de la respuesta de la acción signInUser que contiene los datos devueltos por la solicitud de autenticación al servidor.(la respuesta)
    //? fulfilled -> Cuando una acción asincrónica se resuelve satisfactoriamente
    //? payload -> la carga útil, que contiene datos devueltos la cúal se extrae utilizando la sintaxis de desestructuración en JavaScript: { payload: { data } }.
    builder.addCase(signInUser.fulfilled, (state, { payload: { data } }) => {
      // console.log("Aqui entro sii", Ruta);
      localStorage.setItem("token", JSON.stringify(data.token));
      localStorage.setItem("id_user", JSON.stringify(data?.user?.tipo_user === 2 ? data?.user?.empresa?.id : data?.user?.persona?.id));
      localStorage.setItem("tipo_user", JSON.stringify(data?.user?.tipo_user));
      state.token = data.token;
      state.tipo_user = data?.user?.tipo_user;
      state.id_user = data?.user?.empresa?.id;
      // Redirecciona al usuario a la página de inicio
      const { from } = history?.location?.state || {
        from: { pathname: "inicio" },
      };
      history.navigate(from);

    });
    //! Reducer para la acción rechazada de signInUser
    builder.addCase(signInUser.rejected, (state, action) => {
      state.loading = true;
    });
  },
});

export const { addToken, logout } = authSlice.actions;

export default authSlice.reducer;