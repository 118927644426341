import React, { useEffect, useRef, useState, Suspense, lazy } from "react";
import { Route, Routes, useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { history } from "./history";
import "/node_modules/primeflex/primeflex.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "./Styles/index.css";
import AuthUser from "./AuthUser";
import { Toast } from "primereact/toast";

import Sidebar from "./Pages/Contenedor/Sidebar";

import { sidebarE } from "./Services/SidebarE";
import { sidebarP } from "./Services/SidebarP";
import { logout } from "./Redux/authSlice";

const Registrarse = lazy(() => import("./Pages/Empresa/Registrarse"));

const Index = lazy(() => import("./Pages/Index"));
const PageLogin = lazy(() => import("./Pages/PageLogin"));
const RegistrarseP = lazy(() => import("./Pages/Profesional/RegistrarseP"));

const PageOfertasLaborales = lazy(() => import("./Pages/Empresa/OfertasLaborales/PageOfertasLaborales"));
const PagePostulantes = lazy(() => import("./Pages/Empresa/OfertasLaborales/PagePostulantes"));
const PageFinalistas = lazy(() => import("./Pages/Empresa/OfertasLaborales/PageFinalistas"));
const PageSoporte = lazy(() => import("./Pages/Empresa/SoporteE/PageSoporte"));
const PagePlanesFacturacion = lazy(() => import("./Pages/Empresa/PlanesFacturacion/PagePlanesFacturacion"));

const PageInicioP = lazy(() => import("./Pages/Profesional/PageInicioP"));
const PageBolsaTrabajoP = lazy(() => import("./Pages/Profesional/PageBolsaTrabajoP"));
const MisPostulaciones = lazy(() => import("./Components/Profesional/BolsaTrabajo/MisPostulaciones"));
const PageAcercaP = lazy(() => import("./Pages/Profesional/PageAcercaP"));
const NavBar = lazy(() => import("./Pages/Contenedor/NavBar"));
const PageSoporteP = lazy(() => import("./Pages/Profesional/PageSoporteP"));
const PagePerfilP = lazy(() => import("./Pages/Profesional/PagePerfilP"));
const PageMisEmpresasP = lazy(() => import("./Pages/Profesional/PageMisEmpresasP"));
const CrearEmpresa = lazy(() => import("./Components/Profesional/Empresas/CrearEmpresa"));


const PageInicioEmpresa = lazy(() => import("./Pages/Empresa/OfertasLaborales/PageInicioEmpresa"));
const PageEstadisticasEm = lazy(() => import("./Pages/Empresa/Estadisticas/PageEstadisticasEm"));
const OlvidarPassword = lazy(() => import("./Pages/OlvidarPassword"));
const PerfilE = lazy(() => import("./Pages/Empresa/PerfilE"));
const VentasEmp = lazy(() => import("./Pages/Empresa/Ventas/VentasEmp"));
const PageCompras = lazy(() => import("./Pages/Empresa/Compras/PageCompras"));
const PageCatalogo = lazy(() => import("./Pages/Empresa/Catalogo/PageCatalogo"));
const PageDirectorio = lazy(() => import("./Pages/PageDirectorio"));
const PerfilEmpresaRamdom = lazy(() => import("./Pages/Profesional/PerfilEmpresaRamdom"));
const PageDataOferta = lazy(() => import("./Pages/PageDataOferta"));
const ProdServCompartido = lazy(() => import("./Pages/ProdServCompartido"));
const PageRoles = lazy(() => import("./Pages/Empresa/AdministrarRol/PageRoles"));
const Profesional = lazy(() => import("./Pages/Landing/Profesional"));
const Empresa = lazy(() => import("./Pages/Landing/Empresa"));



function App() {
  const { http } = AuthUser();
  const { tipo_user, id_user } = useSelector((data) => data.data);
  const [dataPersona, setDataPersona] = useState(null);
  const [dataEmpresa, setDataEmpresa] = useState(null);
  const [DataDirectorio, setDataDirectorio] = useState();
  const [DataDirectorioGen] = useState();
  const [Data, setData] = useState([]);
  const [notification, setNotification] = useState([]);
  const [surveySale, setSurveySale] = useState(null);
  const toast = useRef(null);
  const dispatch = useDispatch();
  const location = useLocation();

  history.navigate = useNavigate();
  history.location = useLocation();
  const GetPersona = () => {
    http
      .get("/me")
      .then((response) => {
        setDataPersona(response.data.persona);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          dispatch(logout());
        }
      });
  };
  const GetEmpresa = () => {
    http
      .get(`/empresa/show/${id_user}`)
      .then((response) => {
        setDataEmpresa(response.data);
      })
      .catch((error) => {
      });
  };

  const ListarEmpresa = () => {
    http
      .get("profesional/empresa/get")
      .then((response) => {
        setData(response?.data?.data);
      })
      .catch((error) => {
        console.log("error.response.status", error.response.status);
        const statusCode = error?.response?.status;
        if (statusCode !== 404) console.log("error", error);
      });
  };

  const ListaDirectorio = async () => {
    try {
      const response = await http.get('industria/get/withdata');
      setDataDirectorio(response?.data?.data);
      return response.data;
    } catch (error) {
      console.error('Error al obtener el directorio:', error);
      const statusCode = error?.response?.status;
      if (statusCode !== 404) console.log("error", error);
    }
  };

  useEffect(() => {
    if (tipo_user === 1) GetPersona();
    else if (tipo_user === 2 && id_user !== null) GetEmpresa(id_user);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tipo_user]);

  const subirFoto = (ruta, Foto, setVisible, nombre, Get) => {
    const formDataSend = new FormData();
    formDataSend.append(nombre, Foto.foto);

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    http
      .post(ruta, formDataSend, config)
      .then((response) => {
        Get();
        setVisible();
      })
      .catch((err) => {
        console.log("Algo salió mal al subir la foto", err);
      });
  };

  const encuestaVenta = () => {
    http.get('ventas/preguntas/get')
      .then((response) => {
        setSurveySale(response?.data?.data);
      })
      .catch((error) => {
        console.error('Error fetching survey data:', error);
      });
  };


  const sendEncuesta = (dataToSend) => {
    http.post(`ventas/respuesta/create/${dataEmpresa?.id}`, dataToSend)
      .then((response) => {
        toast.current.show({ severity: 'success', summary: 'Encuesta Enviada', detail: 'Sus respuestas han sido enviadas correctamente.', life: 3000 });
      })
      .catch((error) => {
        toast.current.show({ severity: 'error', summary: 'Error', detail: 'Hubo un problema al enviar sus respuestas.', life: 3000 });
      })
  };

  useEffect(() => {
    encuestaVenta();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return (
    <div>
      <Suspense fallback={<div>Loadding...</div>} >
        {(tipo_user === null || tipo_user === 0) && (
          <Routes>
            <Route path="registrarsee" element={<Registrarse />} />
            <Route path="olvidar" element={<OlvidarPassword />} />
            <Route path="/" element={<Index />} />
            <Route path="*" element={<PageLogin />} />
            <Route path="ladingp" element={<Profesional />} />
            <Route path="ladinge" element={<Empresa />} />
            <Route path="login" element={<PageLogin />} />
            <Route path="registrarsep" element={<RegistrarseP />} />
            <Route path="oferta_laboral/:id" element={<PageDataOferta />} />
            <Route path="subcategoria/:id" element={<ProdServCompartido />} />
          </Routes>
        )}

        {tipo_user === 2 && (
          <div className="flex flex-row">
            <Sidebar SidebarLista={sidebarE} />
            <div className="flex flex-column w-full contenedorGeneralApp">

              {location.pathname !== "/perfil" && (
                <NavBar
                  dataEmpresa={dataEmpresa}
                  tipo_user={tipo_user}
                  ListarEmpresa={ListarEmpresa}
                  Data={Data}
                  notification={notification}
                />
              )}
              <Routes>
                <Route path="*" element={<PageInicioEmpresa />} />
                <Route
                  path="Inicio"
                  element={<PageInicioEmpresa dataEmpresa={dataEmpresa} />}
                />
                <Route
                  path="Directorio"
                  element={
                    <PageDirectorio
                      DataDirectorio={DataDirectorio}
                      ListaDirectorio={ListaDirectorio}
                      DataDirectorioGen={DataDirectorioGen}
                      dataEmpresa={dataEmpresa}
                      tipo_user={tipo_user}
                    />
                  }
                />
                <Route path="MisProductos" element={<PageOfertasLaborales />} />
                <Route
                  path="Catalogo"
                  element={
                    <PageCatalogo
                      dataEmpresa={dataEmpresa}
                      DataDirectorio={DataDirectorio}
                      ListaDirectorio={ListaDirectorio}
                    />
                  }
                />
                <Route
                  path="ofertas/postulantes/:id"
                  element={<PagePostulantes dataEmpresa={dataEmpresa} />}
                />
                <Route
                  path="ofertas"
                  element={<PageOfertasLaborales dataEmpresa={dataEmpresa} />}
                />
                <Route path="estadisticas" element={<PageEstadisticasEm />} />
                <Route
                  path="ofertas/finalistas/:id"
                  element={<PageFinalistas dataEmpresa={dataEmpresa} />}
                />
                <Route
                  path="soporte"
                  element={<PageSoporte dataEmpresa={dataEmpresa} />}
                />
                <Route path="planes" element={<PagePlanesFacturacion />} />
                <Route
                  path="perfil"
                  element={
                    <PerfilE
                      dataEmpresa={dataEmpresa}
                      dataPersona={dataPersona}
                      GetEmpresa={GetEmpresa}
                      subirFoto={subirFoto}
                    />
                  }
                />
                <Route
                  path="compras"
                  element={
                    <PageCompras
                      dataEmpresa={dataEmpresa}
                      GetEmpresa={GetEmpresa}
                    />
                  }
                />
                <Route path="perfil/roles/:id" element={<PageRoles />} />
                <Route
                  path="ventas"
                  element={<VentasEmp
                    dataEmpresa={dataEmpresa}
                    setNotification={setNotification}
                    surveySale={surveySale}
                    sendEncuesta={sendEncuesta}
                  />}
                />
                <Route path="oferta_laboral/:id" element={<PageDataOferta />} />
                <Route
                  path="empresa/:id"
                  element={<PerfilEmpresaRamdom />}
                />
                <Route path="subcategoria/:id" element={<ProdServCompartido />} />
              </Routes>
            </div>
          </div>
        )}
        {tipo_user === 1 && (
          <div className="flex flex-row">
            <Sidebar SidebarLista={sidebarP} />
            <div className="flex flex-column w-full contenedorGeneralApp px-2">
              <NavBar
                dataPersona={dataPersona}
                ListarEmpresa={ListarEmpresa}
                Data={Data}
              />
              <Routes>
                {/* <Route path="CompletarDatosP" element={<PageCompletarDatosP GetEmpresa={GetEmpresa}/>} /> */}
                <Route
                  path="inicio"
                  element={
                    <PageInicioP
                      dataPersona={dataPersona}
                      GetPersona={GetPersona}
                    />
                  }
                />
                <Route path="bolsap" element={<PageBolsaTrabajoP />} />
                <Route
                  path="perfil"
                  element={
                    <PagePerfilP
                      dataPersona={dataPersona}
                      GetPersona={GetPersona}
                      subirFoto={subirFoto}
                    />
                  }
                />
                {/* <Route path="compras" element={<PageMisComprasP />} /> */}
                <Route
                  path="compras"
                  element={
                    <PageCompras
                      dataEmpresa={dataEmpresa}
                      GetEmpresa={GetEmpresa}
                    />
                  }
                />
                <Route
                  path="soporte"
                  element={<PageSoporteP dataPersona={dataPersona} />}
                />
                <Route path="acerca" element={<PageAcercaP />} />
                <Route
                  path="empresas"
                  element={
                    <PageMisEmpresasP ListarEmpresa={ListarEmpresa} Data={Data} />
                  }
                />
                <Route
                  path="postulacionesp"
                  element={<MisPostulaciones ListarEmpresa={ListarEmpresa} />}
                />
                <Route path="crear_empresap" element={<CrearEmpresa data={dataPersona} />} />
                {/* <Route
                path="DirectorioPro"
                element={<PageDirectorioVistaprofesional />}
              /> */}
                <Route
                  path="DirectorioPro"
                  element={
                    <PageDirectorio
                      DataDirectorio={DataDirectorio}
                      ListaDirectorio={ListaDirectorio}
                    />
                  }
                />
                <Route
                  path="empresa/:id"
                  element={<PerfilEmpresaRamdom />}
                />
                <Route path="*" element={<PageInicioP />} />
                <Route path="oferta_laboral/:id" element={<PageDataOferta />} />
                <Route path="subcategoria/:id" element={<ProdServCompartido />} />
              </Routes>
            </div>
          </div>
        )}
        <Toast ref={toast} />
      </Suspense>
    </div>
  );
}

export default App;
